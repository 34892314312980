/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"O nás"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--60 pt--60" name={"6uvgxyxvg5m"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"Kdo jsme"}>
              </Title>

              <Text className="text-box" content={"Působíme na trhu mechanického zabezpečení objektů, majetku a osob od roku 2003, ale zkušenosti v tomto odvětví máme již z dob pozice zaměstnance. Specializujeme se na výrobu klíčů na naší provozovně v Ostravě na Nádražní ulici blízko hlavního nádraží. Kromě výroby klíčů poskytujeme havarijní služby pro nouzové otevírání dveří a automobilů. Mezi naši nabídku služeb dále patří montáže dveřního příslušenství, realizace systému generálního a hlavního klíče, prodej a montáže trezorových skříní, výroba autoklíčů a opravy auto zámků."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-14bc1xq pb--20 pt--80" layout={"l1"} name={"caou39q3g6u"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/19567/89b499e04bab4b23b293f46c1911fab8_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/19567/89b499e04bab4b23b293f46c1911fab8_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/19567/89b499e04bab4b23b293f46c1911fab8_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/19567/89b499e04bab4b23b293f46c1911fab8_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/19567/89b499e04bab4b23b293f46c1911fab8_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/19567/89b499e04bab4b23b293f46c1911fab8_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/19567/89b499e04bab4b23b293f46c1911fab8_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/19567/89b499e04bab4b23b293f46c1911fab8_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --right el--2 flex--bottom" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"<span style=\"color: var(--white);\">Váš KLÍČNÍK</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":334}} content={"<span style=\"color: var(--white);\">Miroslav Košůtek\n<br>Náplatková 216/12, 747 23, Bolatice<br>IČ: 86670565\n<br>DIČ: CZ7302125435</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}